/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A serializer that allows user creation without setting a password. This is used for passwordless registration in the Cart checkout process.
 * @export
 * @interface PasswordlessRegistration
 */
export interface PasswordlessRegistration {
    /**
     * 
     * @type {string}
     * @memberof PasswordlessRegistration
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordlessRegistration
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordlessRegistration
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordlessRegistration
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordlessRegistration
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordlessRegistration
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordlessRegistration
     */
    communicationConsent?: boolean;
}

/**
 * Check if a given object implements the PasswordlessRegistration interface.
 */
export function instanceOfPasswordlessRegistration(value: object): value is PasswordlessRegistration {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    return true;
}

export function PasswordlessRegistrationFromJSON(json: any): PasswordlessRegistration {
    return PasswordlessRegistrationFromJSONTyped(json, false);
}

export function PasswordlessRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordlessRegistration {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'],
        'businessName': json['business_name'] == null ? undefined : json['business_name'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'communicationConsent': json['communication_consent'] == null ? undefined : json['communication_consent'],
    };
}

export function PasswordlessRegistrationToJSON(value?: PasswordlessRegistration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'business_name': value['businessName'],
        'phone_number': value['phoneNumber'],
        'communication_consent': value['communicationConsent'],
    };
}

