/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingStatusEnum } from './FilingStatusEnum';
import {
    FilingStatusEnumFromJSON,
    FilingStatusEnumFromJSONTyped,
    FilingStatusEnumToJSON,
} from './FilingStatusEnum';
import type { PaymentStatusEnum } from './PaymentStatusEnum';
import {
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';
import type { ReportNameEnum } from './ReportNameEnum';
import {
    ReportNameEnumFromJSON,
    ReportNameEnumFromJSONTyped,
    ReportNameEnumToJSON,
} from './ReportNameEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';

/**
 * 
 * @export
 * @interface DynamicFiling
 */
export interface DynamicFiling {
    /**
     * 
     * @type {number}
     * @memberof DynamicFiling
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof DynamicFiling
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DynamicFiling
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {ReportNameEnum}
     * @memberof DynamicFiling
     */
    reportName: ReportNameEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof DynamicFiling
     */
    jurisdiction: JurisdictionEnum;
    /**
     * 
     * @type {FilingStatusEnum}
     * @memberof DynamicFiling
     */
    filingStatus: FilingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DynamicFiling
     */
    paymentType?: string;
    /**
     * Payment status
     * 
     * * `unpaid` - Unpaid
     * * `pending` - Pending
     * * `paid` - Paid
     * @type {PaymentStatusEnum}
     * @memberof DynamicFiling
     */
    paymentStatus?: PaymentStatusEnum;
    /**
     * The date and time the filing was received by governing agency.
     * @type {Date}
     * @memberof DynamicFiling
     */
    receivedTimestamp?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof DynamicFiling
     */
    filedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof DynamicFiling
     */
    approvedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof DynamicFiling
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicFiling
     */
    filingNote?: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicFiling
     */
    percentComplete?: number;
    /**
     * 
     * @type {string}
     * @memberof DynamicFiling
     */
    emailNotificationStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicFiling
     */
    team: number;
}



/**
 * Check if a given object implements the DynamicFiling interface.
 */
export function instanceOfDynamicFiling(value: object): value is DynamicFiling {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('reportName' in value) || value['reportName'] === undefined) return false;
    if (!('jurisdiction' in value) || value['jurisdiction'] === undefined) return false;
    if (!('filingStatus' in value) || value['filingStatus'] === undefined) return false;
    if (!('team' in value) || value['team'] === undefined) return false;
    return true;
}

export function DynamicFilingFromJSON(json: any): DynamicFiling {
    return DynamicFilingFromJSONTyped(json, false);
}

export function DynamicFilingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicFiling {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'reportName': ReportNameEnumFromJSON(json['report_name']),
        'jurisdiction': JurisdictionEnumFromJSON(json['jurisdiction']),
        'filingStatus': FilingStatusEnumFromJSON(json['filing_status']),
        'paymentType': json['payment_type'] == null ? undefined : json['payment_type'],
        'paymentStatus': json['payment_status'] == null ? undefined : PaymentStatusEnumFromJSON(json['payment_status']),
        'receivedTimestamp': json['received_timestamp'] == null ? undefined : (new Date(json['received_timestamp'])),
        'filedDate': json['filed_date'] == null ? undefined : (new Date(json['filed_date'])),
        'approvedDate': json['approved_date'] == null ? undefined : (new Date(json['approved_date'])),
        'completedDate': json['completed_date'] == null ? undefined : (new Date(json['completed_date'])),
        'filingNote': json['filing_note'] == null ? undefined : json['filing_note'],
        'percentComplete': json['percent_complete'] == null ? undefined : json['percent_complete'],
        'emailNotificationStatus': json['email_notification_status'] == null ? undefined : json['email_notification_status'],
        'team': json['team'],
    };
}

export function DynamicFilingToJSON(value?: Omit<DynamicFiling, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'report_name': ReportNameEnumToJSON(value['reportName']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'filing_status': FilingStatusEnumToJSON(value['filingStatus']),
        'payment_type': value['paymentType'],
        'payment_status': PaymentStatusEnumToJSON(value['paymentStatus']),
        'received_timestamp': value['receivedTimestamp'] == null ? undefined : ((value['receivedTimestamp'] as any).toISOString()),
        'filed_date': value['filedDate'] == null ? undefined : ((value['filedDate'] as any).toISOString().substring(0,10)),
        'approved_date': value['approvedDate'] == null ? undefined : ((value['approvedDate'] as any).toISOString().substring(0,10)),
        'completed_date': value['completedDate'] == null ? undefined : ((value['completedDate'] as any).toISOString().substring(0,10)),
        'filing_note': value['filingNote'],
        'percent_complete': value['percentComplete'],
        'email_notification_status': value['emailNotificationStatus'],
        'team': value['team'],
    };
}

