/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressTypeEnum } from './AddressTypeEnum';
import {
    AddressTypeEnumFromJSON,
    AddressTypeEnumFromJSONTyped,
    AddressTypeEnumToJSON,
} from './AddressTypeEnum';
import type { IdTypeEnum } from './IdTypeEnum';
import {
    IdTypeEnumFromJSON,
    IdTypeEnumFromJSONTyped,
    IdTypeEnumToJSON,
} from './IdTypeEnum';

/**
 * 
 * @export
 * @interface CompanyApplicant
 */
export interface CompanyApplicant {
    /**
     * 
     * @type {number}
     * @memberof CompanyApplicant
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyApplicant
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyApplicant
     */
    readonly updatedAt: Date;
    /**
     * FinCEN Identifier (if applicable)
     * @type {string}
     * @memberof CompanyApplicant
     */
    fincenIdentifier?: string;
    /**
     * Full name of the person
     * @type {string}
     * @memberof CompanyApplicant
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    middleName?: string;
    /**
     * e.g. Jr., Sr., III
     * @type {string}
     * @memberof CompanyApplicant
     */
    suffix?: string;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof CompanyApplicant
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    addressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    addressState?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    addressZip?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    addressCountry?: string;
    /**
     * 
     * @type {IdTypeEnum}
     * @memberof CompanyApplicant
     */
    idType?: IdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplicant
     */
    idNumber?: string;
    /**
     * Country of issuance (if Foreign Passport Only
     * @type {string}
     * @memberof CompanyApplicant
     */
    idCountry?: string;
    /**
     * State of issuance (if DL or State ID
     * @type {string}
     * @memberof CompanyApplicant
     */
    idState?: string;
    /**
     * Upload a photo of the identification
     * @type {string}
     * @memberof CompanyApplicant
     */
    idImage?: string;
    /**
     * 
     * @type {AddressTypeEnum}
     * @memberof CompanyApplicant
     */
    addressType?: AddressTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyApplicant
     */
    beneficialOwnershipReport: number;
}



/**
 * Check if a given object implements the CompanyApplicant interface.
 */
export function instanceOfCompanyApplicant(value: object): value is CompanyApplicant {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('beneficialOwnershipReport' in value) || value['beneficialOwnershipReport'] === undefined) return false;
    return true;
}

export function CompanyApplicantFromJSON(json: any): CompanyApplicant {
    return CompanyApplicantFromJSONTyped(json, false);
}

export function CompanyApplicantFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyApplicant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'fincenIdentifier': json['fincen_identifier'] == null ? undefined : json['fincen_identifier'],
        'fullName': json['full_name'] == null ? undefined : json['full_name'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'suffix': json['suffix'] == null ? undefined : json['suffix'],
        'dateOfBirth': json['date_of_birth'] == null ? undefined : (new Date(json['date_of_birth'])),
        'addressLine1': json['address_line_1'] == null ? undefined : json['address_line_1'],
        'addressCity': json['address_city'] == null ? undefined : json['address_city'],
        'addressState': json['address_state'] == null ? undefined : json['address_state'],
        'addressZip': json['address_zip'] == null ? undefined : json['address_zip'],
        'addressCountry': json['address_country'] == null ? undefined : json['address_country'],
        'idType': json['id_type'] == null ? undefined : IdTypeEnumFromJSON(json['id_type']),
        'idNumber': json['id_number'] == null ? undefined : json['id_number'],
        'idCountry': json['id_country'] == null ? undefined : json['id_country'],
        'idState': json['id_state'] == null ? undefined : json['id_state'],
        'idImage': json['id_image'] == null ? undefined : json['id_image'],
        'addressType': json['address_type'] == null ? undefined : AddressTypeEnumFromJSON(json['address_type']),
        'beneficialOwnershipReport': json['beneficial_ownership_report'],
    };
}

export function CompanyApplicantToJSON(value?: Omit<CompanyApplicant, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fincen_identifier': value['fincenIdentifier'],
        'full_name': value['fullName'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'middle_name': value['middleName'],
        'suffix': value['suffix'],
        'date_of_birth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString().substring(0,10)),
        'address_line_1': value['addressLine1'],
        'address_city': value['addressCity'],
        'address_state': value['addressState'],
        'address_zip': value['addressZip'],
        'address_country': value['addressCountry'],
        'id_type': IdTypeEnumToJSON(value['idType']),
        'id_number': value['idNumber'],
        'id_country': value['idCountry'],
        'id_state': value['idState'],
        'id_image': value['idImage'],
        'address_type': AddressTypeEnumToJSON(value['addressType']),
        'beneficial_ownership_report': value['beneficialOwnershipReport'],
    };
}

